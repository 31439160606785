import React from 'react';
import classes from './howItWorksModal.module.css';

const HowItWorksModal = ({ closeModal }) => {
    return (
        <div className={classes.background}>
            <div className={classes.container}>
                <div className={classes.title}>
                    <h1>How It Works:</h1>
                </div>
                <div className={classes.body}>
                    <ul>
                        <li>Step 1: Complete offers to earn Coins. 100 coins = $1 USD.</li>
                        <li>Step 2: Choose a payment method.</li>
                        <li>Step 3: Collect your rewards directly!</li>
                    </ul>
                </div>
                <div className={classes.footer}>
                    <button onClick={closeModal}>Get Started</button>
                </div>
            </div>
        </div>
    );
};

export default HowItWorksModal;