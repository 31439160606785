import React, { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import HowItWorksModal from '../../components/HowItWorksModal/HowItWorksModal';

export default function MyChips() {

    const { user } = useAuth();
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
      const justRegistered = localStorage.getItem('justRegistered');
      if (justRegistered === 'true') {
        setShowModal(true);
        localStorage.removeItem('justRegistered');
      }
    }, []);
  return (
    <>
    <iframe width="100%" frameBorder="0" height="1000px" src={`https://trk301.com?cid=2607531&pid=2991&adunit_id=d4270dc8-54a2-4499-994e-c1abe6c939e5&user_id=${user.id}`}></iframe>
    {showModal && <HowItWorksModal closeModal={() => setShowModal(false)} />}
    </>
  )
}